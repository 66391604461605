import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import CallingBlock from "../components/CallingBlock"
import Widget from '../components/Widget'
import SignUpSection from '../components/SignUpSection'

export default function Test({ data }) {

  const pageData = data.markdownRemark.frontmatter
  let article = data.markdownRemark.html
  const showWidgets = data.markdownRemark.frontmatter.would_you_like_to_show_widgets_bellow_the_main_article
  const widgets = data.markdownRemark.frontmatter.bellow_article_widgets
  const showCallingBlock = data.markdownRemark.frontmatter.show_calling_block
  const callingBlockData = data.calling_block.frontmatter

  const displayWidgets = (widgets) => (
    widgets.map( widget => (
      <Widget
        key={ widget.widget_link_url }
        headline={ widget.widget_title } 
        text={ widget.widget_content } 
        label={ widget.widget_link_label }  
        link={ widget.widget_link_url }  
      />
    ))
  )

  return (
    <>
      <Seo title={pageData.page_title} />

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-11">
                <h1>{pageData.hero_headline}</h1>
                <article dangerouslySetInnerHTML={{ __html: article }} />

                {showWidgets === true ? displayWidgets(widgets) : ""}

                { showCallingBlock === true && 
                    <CallingBlock 
                    title={callingBlockData.block_title} 
                    headline={callingBlockData.block_headline}
                    content={callingBlockData.block_content} 
                    block_call_to_action={callingBlockData.block_call_to_action} 
                    image={callingBlockData.block_image} 
                    /> 
                }
            </div>
          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}

// = = = = = = = = = = = = =
// GraphQL
export const query = graphql`
  query TestQuery {
    markdownRemark(frontmatter: { page_title: { eq: "This is a test page" } }) {
      frontmatter {
        page_title
        hero_headline
        hero_intro
        sidebar_links {
          link_label
          link_url
        }
        would_you_like_to_show_widgets_bellow_the_main_article
        bellow_article_widgets {
          widget_title
          widget_content
          widget_link_label
          widget_link_url
        }
        show_calling_block
      }
      headings(depth: h2) {
        value
      }
      html
    }
    calling_block: markdownRemark(frontmatter: { block_title: { eq: "Calling block" } }) {
        frontmatter {
            block_title
            block_headline
            block_content
            block_call_to_action {
                button_label
                button_url
            }
            block_image {
                childImageSharp {
                gatsbyImageData
              }
            }
        }
    }
  }
`
